.icon-bay-wrapper {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  width: 700px;
  background-color: white;
  border: 1px solid rgb(216, 216, 216);
  filter: drop-shadow(0 0 4px #a8a8a8);
  border-radius: 2rem;
  padding: 1rem;
  z-index: 999;

  &.make-right {
    left: unset;
    right: 0;
  }
}

.icon-body-contain {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: scroll;

  i {
    font-size: 2rem;
    color: #7c7d80;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      font-size: 2.2rem;
      margin-top: -0.2rem;

      & + span {
        font-weight: bold;
      }
    }
  }

  .icon-contain {
    flex: 0 1 16%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
  }

  span {
    font-size: 0.6rem;
    text-align: center;
    transition: 0.2s;
  }
}

.icon-display-contain {
  display: flex;
  height: 100%;
  align-items: center;

  i {
    min-width: 1rem;
    text-align: center;
  }
}

.icon-display-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  align-items: center;

  i + i {
    margin: 0.5rem 0.2rem 0.4rem 0.3rem;
  }

  .icon-bay-backsplash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
  }

  &.isOpen,
  &:hover {
    background-color: white;
    border: 1px solid rgb(216, 216, 216);
    box-shadow: 0 0 15px rgb(206, 206, 206);
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
  }
}
