.grid-item-edit-drawer {
  min-height: 7rem;
  .bootstrap-tagsinput {
    .badge {
      font-size: $font-size-tn;
      font-weight: $font-weight-normal;
      font-family: $font-family-sans-serif;
      text-transform: none;
      background-color: var(--brand-primary-color);
    }
  }
}

.labeled-checkbox {
  line-height: 2rem;

  input {
    margin-top: 0.7rem;
  }

  .form-check-label {
    .form-check-input {
      border-radius: 0.25em;
      border: 1em solid currentColor;
    }
  }

  .labeled-checkbox-label {
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
  }
}

.labeled-checkbox-group {
  margin-left: 1.3rem;
}

.multi-edit-columns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.multi-edit-wrapper,
.tags-input-wrapper {
  display: flex;
  flex-direction: row;

  .title-wrapper {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-size: $h5-font-size;
    justify-content: center;
    line-height: $font-size-sm;
    min-height: 2.2rem;
    padding-right: 1.8rem;
    text-align: right;
    text-transform: uppercase;
    width: 10rem;
  }

  .bootstrap-tagsinput {
    background-color: inherit;
    width: 100%;

    .tag {
      a {
        color: transparent;
      }
    }
  }

  .bootstrap-tagsinput {
    .tag:hover {
      a {
        color: #fff;
      }
    }
  }
}

.multi-edit-dropdown-menu {
  margin-top: 0.3rem;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 21.1rem;

  .navbar-search .form-control {
    width: 100%;
  }

  .card {
    margin-bottom: 0;
    // padding-bottom: $modal-inner-padding;

    .card-header {
      padding: 0.5rem;

      .form-group {
        width: 100%;

        .input-group-alternative {
          width: 100%;
        }
      }
    }

    .card-body {
      position: relative;
      padding: 0.5rem 2rem;
      max-height: 400px;
      overflow-y: scroll;

      .labeled-checkbox {
        .labeled-checkbox-icon {
          margin-left: 0.4rem;
        }

        .labeled-checkbox-label {
          margin-left: 0.7rem;
          font-size: $h6-font-size;
        }
      }
    }
  }

  .selected-notice {
    width: 100%;
    height: 1.3rem;
    background-color: $blue-gray;
    text-align: center;
    line-height: 1.1rem;

    span {
      font-size: $font-size-xs;
      color: $white;
    }
  }
}

.multi-edit-button {
  width: 11.1rem;
  height: 2.2rem;
  border: 2px solid gray;
  border-radius: 1rem;
  text-align: left;
  padding-left: 1.3rem;

  &-title {
    font-size: $h6-font-size;
    line-height: 2rem;
    display: inline;
  }

  i {
    line-height: 2rem;
    margin-right: 0.6rem;
    font-size: $font-size-xs;
  }
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: var(--brand-primary-color);
  color: var(--theme-dark-text-color);
}

.table td.category-search {
  min-width: 20rem;
}

.tags-input-wrapper {
  .title-wrapper {
    padding-top: 0.3rem;
    justify-content: flex-start;
  }
}

.text-input-editable {
  cursor: default;
}

.toggle-switch {
  display: inline-block;
  padding-left: 1rem;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 55px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &-checkbox {
    display: none;
  }

  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }

  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }

  &-inner {
    display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;

    &:before,
    &:after {
      box-sizing: border-box;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: bold;
      height: 24px;
      line-height: 34px;
      padding: 0;
      width: 50%;
    }

    &:before {
      background-color: #fff;
      content: '';
      color: #fff;
      padding-left: 10px;
      text-transform: uppercase;
    }
  }

  &-inner:after {
    background-color: #fff;
    content: '';
    color: #fff;
    padding-right: 10px;
    text-align: right;
    text-transform: uppercase;
  }

  &-label {
    border: 0 solid #bbb;
    border-radius: 20px;
    cursor: pointer;
    display: block;
    margin: 0;
    overflow: hidden;
  }

  &-switch {
    background: #4d4d4d;
    border: 0 solid #bbb;
    border-radius: 20px;
    bottom: 0;
    display: block;
    margin: 5px;
    position: absolute;
    right: calc(100% - 43px);
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 15px;
  }
}

.simple-toggle-switch {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 20px;
  transition: 0.3s;
  width: 40px;

  &.checked {
    background-color: var(--brand-primary-color);

    div {
      background-color: #fff;
      margin-left: 21px;
    }
  }

  div {
    background-color: var(--brand-primary-color);
    border-color: var(--brand-primary-color);
    border-radius: 7px;
    margin: 5px;
    height: 14px;
    transition: 0.3s;
    width: 14px;
  }
}

.row-right-aligned-label {
  display: flex;
  font-size: 0.8125rem;
  justify-content: flex-end;
  padding-right: 1.8rem;
  padding-top: 0.5rem;
  text-transform: uppercase;
}

.collection-image-asset-contain {
  cursor: pointer;
  max-width: 140px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.storyslab-select {
  position: relative;

  .storyslab-select-control {
    align-items: center;
    border: 2px solid gray;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    height: 2.2rem;
    justify-content: space-between;
    padding-left: 1.3rem;
    text-align: left;

    .storyslab-select-control-title {
      font-size: $h6-font-size;
      line-height: 2rem;
      display: inline;
    }

    i {
      padding-right: 0.75rem;
    }
  }

  .storyslab-select-options-contain {
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: $border-radius-large;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: hidden;
    padding: 1rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    .storyslab-select-search {
      align-items: center;
      border-radius: $border-radius-large;
      box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
      display: flex;
      margin-bottom: 1.5rem;
      padding: 0.5rem;

      input[type='text'] {
        border: none;
        flex: 1;
      }
    }

    .storyslab-select-options {
      overflow-y: scroll;
      padding-right: 1rem;

      &::-webkit-scrollbar-thumb {
        background: #0065a3;
      }
      &::-webkit-scrollbar {
        background-color: rgba(0, 0, 0, 0.05);
        width: 8px;
      }

      .storyslab-select-checkbox {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-bottom: 0.5rem;

        i {
          color: #4d4d4d;
          font-size: 18px;
          margin-right: 0.5rem;
        }

        span {
          color: #999;
          font-size: 10px;
          font-weight: 700;
        }
      }
    }
  }
}

.clear-filter-contain {
  align-items: center;
  color: #0065a3;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: flex-end;
  margin: 0 2rem 1rem;

  div {
    cursor: pointer;
  }

  i {
    margin-top: 2px;
    padding-right: 0.5rem;
  }
}
