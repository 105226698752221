.login-hr {
  align-items: center;
  background-color: #979797;
  display: flex;
  height: 1px;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  width: 348px;

  div {
    align-items: center;
    background-color: #fff;
    color: #4d4d4d;
    display: flex;
    font-size: 12px;
    justify-content: center;
    width: 35px;
  }
}

.login-card-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1px;
  width: 100%;

  .login-card {
    align-items: center;
    background-color: #fff;
    border-radius: $border-radius-large;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 662px;

    .brand-icon-wrapper {
      border-radius: $border-radius-small;
      box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
      height: 220px;
      margin: -63px 0 25px 0;
      overflow: hidden;
      width: 220px;

      .brand-icon {
        width: 100%;
        border-radius: 0.375rem;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      }
    }

    .login-fields {
      width: 348px;

      .login-icon-spacer {
        width: 38px;
      }
    }

    .login-simple-text-contain {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 3rem;
      margin-top: 1rem;
      max-width: 454px;
      text-align: center;
    }
  }

  .login-card-title {
    color: #4d4d4d;
    font-size: 18px;
    font-weight: 700;
  }
}

button.login-flow-button.btn {
  margin-right: 0;
  text-transform: uppercase;
  width: 348px;
}

.login-meta {
  align-items: center;
  color: var(--brand-primary-color);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.login {
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .btn-icon {
    margin-top: 1em;
    width: 100%;
  }

  .btn + .btn {
    margin-left: 0;
  }

  .bg-secondary {
    background-color: rgba(247, 250, 252, 0.8) !important;
  }

  .pi {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
  }
}

.login-code,
.app-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .storyslab-logo {
    height: 20vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .storyslab-logo {
      animation: storyslab-logo-spin infinite 10s linear;
    }
  }
}

@keyframes storyslab-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
