.indicator-save-status-wrapper {
  display: flex;
  color: var(--brand-primary-color);
  min-height: 1.5rem;

  &.right {
    justify-content: flex-end;
    margin-right: 1.5rem;
  }
}

.indicator-save-status {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;

  i {
    left: -1.4rem;
    position: absolute;
    padding-top: 0.1rem;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;

    &.active {
      opacity: 1;
      transition: opacity 0.3s, transform 0.3s;
    }
  }
}

.loader-wrapper {
  z-index: 99;
}

.toast-container {
  background-color: #fff;
  bottom: -200px;
  filter: drop-shadow(0 0 0.75rem rgb(218, 218, 218));
  right: 1rem;
  padding: 1.5rem;
  position: fixed;
  transition: 2.5s;
  width: 500px;

  &.animate-up {
    bottom: 1rem;
  }

  .toast-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
