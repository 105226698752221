.pagination {
  align-items: center;
  justify-content: center;

  .page-item.active {
    .page-link {
      background-color: var(--brand-primary-color);
      color: var(--theme-dark-text-color);
    }
  }
}

.items-per-page-dropdown,
.items-per-page-option {
  transition: 0.2s;

  &:hover {
    background-color: rgb(233, 233, 233);
  }
}
