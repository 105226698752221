.navbar-vertical {
  .navbar-brand-img,
  .navbar-brand > img {
    border-radius: $border-radius-small;
    box-shadow: $box-shadow;
    height: 65px;
    width: 65px;
  }
}
.nav-link > i,
.nav-link > span {
  font-weight: bold;
}
.nav-link + .collapse .nav-link > i,
.nav-link + .collapse .nav-link span {
  font-weight: normal;
}
.nav-link + .collapsing .nav-link > i,
.nav-link + .collapsing .nav-link span {
  font-weight: normal;
}
.nav-link.active i,
.nav-link.active span {
  color: var(--brand-primary-color);
}

#main-navigation {
  i {
    float: right;
    font-size: 1.4rem;
  }

  .sidenav-mini-icon i {
    font-size: 1rem;
    display: inline-block;
    margin-left: 0.5rem;
    width: 100%;
  }
}
#main-navigation .nav-link-text {
  font-size: 0.8rem;
  text-transform: uppercase;
}

#main-navigation .navbar-nav > .nav-item {
  margin: 1rem 0 0 0;
}
