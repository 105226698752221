html,
body,
#root {
  height: 100% !important;
}

a,
.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed !important;
}

.fa,
.far,
.fas {
  font-family: 'Font Awesome 5 Pro' !important;
}

.make-100 {
  width: 100%;
}

.make-pointer {
  cursor: pointer;
}

.make-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.make-full {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
}

.no-max-width {
  max-width: unset;
}

.btn-storyslab {
  background-color: var(--brand-primary-color);
  color: #fff;
  white-space: nowrap;
}

.backsplash {
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99998;
}

.detail-backsplash {
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

.container-fluid h4 {
  position: relative;
  z-index: 4;
}

.hamburger-menu-card-contain {
  position: relative;

  i {
    border-radius: $border-radius-small;
    cursor: pointer;
    font-size: 2rem;
    padding: 0.5rem 1rem 0.3rem;
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .hamburger-menu-card {
    background-color: #4d4d4d;
    border-radius: $border-radius-small;
    color: #f6f7fe;
    cursor: auto;
    right: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 100%;
    width: 300px;
    z-index: 100000;

    .menu-option {
      cursor: pointer;
      padding: 0.5rem;
      transition: 0.2s;

      &:hover {
        background-color: #292929;
        padding-left: 0.7rem;
      }
    }
  }
}
