.modal-contents {
  .header-row {
    align-items: center;
    display: flex;
    flex: 1;
    padding: 0 0 18px;

    .modal-icon {
      color: var(--brand-primary-color);
      font-size: 35px;
      margin-right: 20px;
    }

    .modal-title {
      color: #808080;
      flex: 1;
      font-size: 12px;
      font-weight: 700;
    }

    .modal-text {
      color: #808080;
      flex: 2;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.standardContentContain > div {
  flex: 1;
  padding: 5px;

  .settings-subtitle {
    color: #808080;
    font-size: 12px;
    font-weight: 700;
  }
}
