.sidenav.navbar-vertical {
  border: none;
  box-shadow: none;
  overflow: visible;
}

.sidenav .navbar-brand {
  display: block;
}

.sidenav-header {
  border-bottom: 1px solid #e9ecef !important;
  height: calc(2rem + 47px);
  min-width: 250px;
  position: relative;
  z-index: 1051;
}

@media (max-width: 1200px) {
  .g-sidenav-show .sidenav .navbar-brand {
    justify-content: flex-start;
  }
}

@media (max-width: 575px) {
  .sidenav-header {
    display: none !important;
  }
}

.navbar-inner {
  height: 100%;
}

.nav-link.active.active-override span {
  color: #666 !important;
}

.g-sidenav-show {
  .sidenav {
    max-width: 250px !important;
    transform: translate(0) !important;
  }
}

.g-sidenav-hidden {
  .sidenav {
    max-width: 62px !important;
    transform: translate(-62px) !important;
  }
  .navbar-vertical.navbar-expand-xs {
    .navbar-nav {
      .nav {
        .nav-link {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .g-sidenav-hidden {
    .sidenav {
      transform: translate(0) !important;
    }
  }
  .sidenav.fixed-left + .main-content {
    // z-index: 9999;
    margin-left: 62px !important;
  }
  .sidenav-toggler {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .g-sidenav-hidden:not(.g-sidenav-show) {
    .sidenav {
      max-width: 250px !important;
      .navbar-brand,
      .navbar-heading,
      .nav-item .collapse .sidenav-normal,
      .nav-link-text {
        display: block !important;
        opacity: 1;
      }
    }
  }

  .sidenav.fixed-left + .main-content {
    margin-left: 250px !important;
  }
}
