.detail-view-wrapper {
  position: relative;
  z-index: 0;

  &:nth-child(2n + 0) .detail-row-wrapper {
    background-color: #f5f5f5;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.dropped,
  &.hamburger-open {
    z-index: 4;
  }
}

.detail-row-wrapper {
  background-color: #fff;
  border-radius: $border-radius-small;
  cursor: pointer;
  margin-bottom: 0;
  padding: 5px 25px;
  position: relative;
  transition: 0.5s;
  z-index: 2;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &.active {
    box-shadow: 0px 0px 10px #999;

    &:hover {
      background-color: #fff;
    }

    &.odd {
      background-color: #fff;

      &:hover {
        background-color: #fff;
      }
    }
  }

  &.odd {
    background-color: rgba(0, 0, 0, 0.025);

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .detail-row-body {
    align-items: center;
    display: flex;

    .detail-row-thumbnail {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 1rem 0 0;
      overflow: hidden;
      width: 40px;

      img {
        width: 100%;
      }

      i {
        font-size: 24px;
      }
    }

    .detail-row-title {
      color: #808080;
      flex: 2;
      font-size: 14px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .detail-row-meta {
      align-items: center;
      display: flex;
      position: relative;

      .meta-text {
        color: #808080;
        font-size: 12px;
        font-weight: 700;
        padding: 0 1rem;
      }
    }

    .preview-button {
      align-items: center;
      background-color: var(--brand-primary-color);
      border-radius: 1rem;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      height: 2rem;
      justify-content: center;
      margin-right: 3rem;
      transition: 0.2s;
      width: 2rem;

      &:hover {
        box-shadow: $box-shadow;
      }
    }

    .preview-stack-list {
      background-color: #4d4d4d;
      cursor: default;
      position: absolute;
      right: 0;
      top: calc(100% + 5px);
      width: 300px;
      z-index: 100000;

      .preview-item {
        align-items: center;
        display: flex;
        font-size: 14px;
        padding: 0.5rem;

        .item-name {
          flex: 1;
          font-size: 14px;
          font-weight: 700;
          margin-left: 0.5rem;
        }

        .preview-icon {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 40px;
        }

        .preview-item-menu-contain {
          position: relative;

          i {
            border-radius: $border-radius-tiny;
            color: #b3b3b3;
            cursor: pointer;
            padding: 0.5rem;
            transition: 0.2s;

            &.open {
              background-color: #fff;
            }

            &:hover {
              background-color: #fff;
            }
          }

          .preview-menu {
            background-color: #fff;
            border-radius: $border-radius-small;
            color: #b3b3b3;
            cursor: pointer;
            padding: 0.5rem 1rem;
            position: absolute;
            right: 0;
            top: calc(100% + 5px);
            white-space: nowrap;
            z-index: 100000;
          }
        }
      }
    }

    .detail-done {
      font-size: 16px;
      font-weight: 800;
      margin-right: 3rem;
    }
  }
}

.detail-row-dropped-content {
  background-color: #fff;
  border-radius: $border-radius-small;
  display: flex;
  height: 0;
  margin: 0 5px;
  overflow: hidden;
  padding-top: 8px;
  position: absolute;
  top: calc(100% - 8px);
  transition: 0.5s;
  width: calc(100% - 10px);
  z-index: 1;

  &.dropped {
    filter: drop-shadow(0 0 5px #999);
    height: 373px;
  }

  .menu {
    background-color: #4d4d4d;
    border-radius: 0 0 0 $border-radius-small;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    padding-top: 16px;
    width: 259px;

    div {
      cursor: pointer;
      padding: 8px 0 8px 34px;
      transition: 0.25s;

      &:hover {
        background-color: var(--brand-primary-color);
        padding-left: 36px;
      }

      &.active {
        background-color: var(--brand-primary-color);
      }
    }
  }

  .content-contain {
    display: flex;
    flex: 1;
    padding: 2rem 4rem;

    > div {
      flex: 1;
    }

    .form-field-contain {
      margin-right: 3rem;
      max-width: 100%;
      height: 75px;

      .form-field-label {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      input[type='text'] {
        border: none;
        border-bottom: 1px solid #000;
        padding-left: 10px;
        width: 100%;
      }

      .detail-button {
        align-items: center;
        background-color: #fff;
        border-radius: $border-radius-large;
        cursor: pointer;
        display: flex;
        filter: drop-shadow(0 3px 5px rgb(182, 182, 182));
        font-size: 14px;
        font-weight: 700;
        height: 30px;
        justify-content: center;
        text-transform: capitalize;
        transition: 0.2s;
        width: 200px;

        &:hover {
          filter: drop-shadow(0 3px 2px rgb(182, 182, 182));
        }

        &.populated {
          font-size: 12px;
          justify-content: flex-start;

          i {
            color: #0065a3;
            font-size: 14px;
            padding: 0.5rem;
          }
        }
      }

      .image-field {
        cursor: pointer;
        margin-left: 0.5rem;
        width: 100px;
      }
    }

    .boolean-form-field-contain {
      align-items: center;
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      padding: 0.75rem 0;

      .form-field-label {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}

.storyslab-multiselect {
  .button {
    align-items: center;
    border: 2px solid gray;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    height: 2.2rem;
    justify-content: space-between;
    padding-left: 1.3rem;
    text-align: left;

    i {
      padding-right: 0.75rem;
    }
  }
}

.detail-dropdown {
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-large;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  max-height: 300px;
  margin-top: 0.5rem;
  overflow: hidden;
  padding: 1rem;
  position: absolute;
  width: 330px;
  z-index: 99999;

  .detail-search {
    align-items: center;
    border-radius: $border-radius-large;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
    display: flex;
    margin-bottom: 1.5rem;
    padding: 0.5rem;

    input {
      border: none;
      flex: 1;
    }
  }

  .detail-separator {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.05);
    margin: auto;
    margin-bottom: 1rem;
    width: 80%;
  }

  .detail-body {
    overflow-y: scroll;
    padding-right: 1rem;

    &::-webkit-scrollbar-thumb {
      background: #0065a3;
    }
    &::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0.05);
      width: 8px;
    }
  }

  .detail-checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 1rem;

    .input-contain {
      align-items: center;
      border: 2px solid #4d4d4d;
      border-radius: 2px;
      display: flex;
      height: 16px;
      justify-content: center;
      margin-right: 1rem;
      width: 16px;

      i {
        color: #4d4d4d;
        font-size: 10px;
        font-weight: bold;
        margin-top: 1px;
      }
    }

    > i {
      color: #999;
      font-size: 18px;
      margin-right: 1rem;
    }

    span {
      color: #999;
      font-size: 10px;
      font-weight: 700;
    }
  }

  .list-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 25px;
  }
}

.thumbnail-container {
  display: inline-block;
  margin-right: 0.5rem;
  height: 30px;
  width: 30px;

  > img {
    width: 100%;
  }
}

.detail-row-table-header {
  align-items: center;
  color: #8898aa;
  display: flex;
  font-size: 10px;
  font-weight: 700;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-left: calc(65px + 0.85rem);
  padding-right: 5rem;

  i {
    cursor: pointer;
    font-size: 12px;
    padding: 0 0.5rem;
  }
}

// Change Source & Frequency Buttons
.source-input-wrapper {
  .source-contain {
    background-color: #fff;
    border-color: rgb(227, 227, 227);
    border-top-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 0;
    transition: 0.2s;

    i {
      color: #4d4d4d;
      transition: 0.2s;
    }

    &:hover {
      background-color: #4d4d4d;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  &.active {
    .source-contain {
      background-color: #4d4d4d;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }
}

// Source Menu Dropdown
.source-menu-wrapper {
  border-radius: 3px;
  margin-top: 0;
  position: absolute;
  top: 115px;
  width: 259px;
  z-index: 100000;
}
