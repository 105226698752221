.btn.drawerButton {
  border-radius: 1rem;
  color: #fff;
  font-size: 0.5rem;
  text-transform: uppercase;

  &:hover {
    background-color: #fff !important;
    border-radius: 1rem;
    color: #333;
    font-size: 0.5rem;
    text-transform: uppercase;
  }
}

.card-is-checked {
  align-items: center;
  background-color: var(--brand-primary-color);
  border-radius: 0 1rem 1rem 0;
  display: flex;
  height: 100%;
  justify-content: center;

  &.error {
    background-color: #c32129;
  }

  i {
    font-size: 1.8rem;
  }
}

.dark-row {
  background-color: #f9f9f9;
}

.layout-configurator-card {
  background-color: #4d4d4d;
  border-color: #4d4d4d;
  border-radius: 1rem;
  transition: 0.3s;

  &.error:hover {
    background-color: #c32129 !important;
  }

  &:hover {
    background-color: var(--brand-primary-color) !important;

    * {
      color: #fff;
      transition: 0.2s;
    }

    .card-is-checked i {
      font-size: 2rem;
    }
  }

  &.not-visible {
    opacity: 0.5;
  }
}

.layout-nav-item-name {
  border: none;
  font-weight: bold;
}

.layout-preview {
  display: none;

  &.show {
    display: block;
  }
}

.list-contain {
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem 0 rgba(100, 100, 100, 0.26);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .card {
    box-shadow: none;
    margin-bottom: 0;
  }
}

.nav-item-card-wrapper {
  border-radius: 0;
  margin-bottom: 0;
  transition: 0.3s;

  &:hover {
    background-color: var(--brand-primary-color) !important;

    .nav-item-title,
    i {
      color: #fff;
    }

    .edit-icon {
      border-color: #fff;
    }

    input {
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }

  .edit-icon {
    align-items: center;
    background-color: var(--brand-primary-color);
    border: 1px solid var(--brand-primary-color);
    border-radius: 2rem;
    color: #fff;
    display: flex;
    height: 3rem;
    justify-content: center;
    margin-left: 1rem;
    width: 3rem;

    &:hover {
      background-color: #fff;

      i {
        color: var(--brand-primary-color);
      }
    }
  }

  .item-icon {
    color: var(--brand-primary-color);
  }

  .nav-item-card-contain {
    align-items: center;
    display: flex;
    padding: 1rem;
    position: relative;

    .grip-icon {
      cursor: pointer;
      margin-right: 0.5rem;
    }

    > i.fa {
      padding: 1rem;
    }

    .nav-item-title {
      flex: 1;
      font-weight: 900;
      margin: 0;
      overflow: hidden;

      input {
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #fff;
      }
    }

    .nav-item-status {
      background-color: #c32129;
      border-radius: 0.5rem;
      color: #fff;
      font-size: 0.8rem;
      padding: 0.5rem 2rem;
    }
  }
}

.nav-item-list-title {
  font-size: 0.8rem;
  margin-left: 1rem;
  text-transform: uppercase;
}

.layout-configurator-card-target {
  font-weight: bold;
}

.simpleDrawerHeader {
  font-weight: bold;
}

.simpleDrawerRow button.btn:hover:before {
  background: none;
}

.component-control-wrapper {
  &:hover {
    .component-control-contain {
      background-color: #84d5c2;
      color: #4d4d4d;
    }

    i {
      color: #4d4d4d;
    }
  }

  &.notVisible {
    opacity: 0.8;
  }

  .component-control-contain {
    background-color: #4d4d4d;
    color: #fff;
  }

  i {
    color: #84d5c2;
  }
}
