.btn {
  &:hover,
  &:active {
    background-color: #fff;
    color: var(--theme-light-text-color);
    transform: none;
  }
}

.btn.btn-main {
  background-color: var(--brand-primary-color);
  color: #fff;
}

.btn-default,
.storyslab-button {
  background-color: var(--brand-primary-color);
  border-radius: $border-radius-large;
  color: var(--theme-dark-text-color);
  font-size: 9px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: rgb(222, 222, 222);
    color: #151515;
  }

  &.light {
    background-color: #fff;
    color: #151515;

    &:hover {
      background-color: rgb(222, 222, 222);
      color: #151515;
    }
  }
}

.btn-light {
  background-color: #fff;
  color: #151515;

  &:hover {
    background-color: rgb(222, 222, 222);
    color: #151515;
  }
}

.btn-default.inverse {
  background-color: #4d4d4d;
  border-radius: 1rem;
  font-size: 0.5rem;
  text-transform: uppercase;
  color: #fff;
  transition: 0.2s;
}

.btn-default.inverse:hover {
  background-color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
  color: var(--theme-dark-text-color);
}

.btn-default.inverse:hover:before {
  background: none;
}

.rounded-circle {
  &:hover:before,
  &:active:before {
    border-radius: 50% !important;
  }
}

// :before {
//   content: "";
//   position: absolute;
//   left: 0; right: 0;
//   top: 0; bottom: 0;
//   background: rgba(0,0,0,.5);
// }

.btn-outline-default {
  color: var(--brand-primary-color);
}

.btn-outline-default:hover {
  border-color: transparent !important;
}

.button-tiny {
  font-size: $font-size-tn;
  padding: 0.1rem 0.5rem;
}

// .btn-group,
// .btn-group-vertical {
//   position: relative;
//   display: inline-flex;
//   vertical-align: middle;
// }

.provider-button {
  align-items: center;
  border: 1px solid var(--brand-primary-color);
  border-radius: $border-radius-tiny;
  color: #6d7278;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  margin: 0.5rem 0;
  overflow: hidden;
  padding: 0.2rem 1rem;
  transition: 0.3s;
  width: 348px;

  &-bumper {
    height: 28px;
    width: 28px;
  }

  &.btn {
    background-color: #fff;
    margin: 0;
  }

  &:hover {
    background-color: var(--brand-primary-color);
    color: #fff;
  }

  img {
    height: 28px;
    width: 28px;
  }
}

.component-button {
  background-color: var(--brand-primary-color);
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 20px;
  margin: 1rem auto 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
}
