.titleSubtitleDropdown {
  &--title {
    color: var(--theme-dark-text-color);
    margin-bottom: 0;
  }

  &--subtitle {
    font-size: $font-size-base * 0.7;
    padding-right: 0.4rem;

    i {
      margin-left: 0.4rem;
      margin-right: 0.4rem;
    }
  }

  &--subtitle.first {
    i {
      margin-left: 0;
    }
  }
}
