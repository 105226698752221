.card-container {
  background-color: #fff;
  border-radius: $border-radius-large;
  box-shadow: 0 0 15px #e9e9e9;
  overflow: hidden;
  padding: $space3;
  transition: 0.3s;

  &.clickable:hover {
    box-shadow: 0 0 15px #c7c7c7;
  }
}

.storyslab-table-row {
  align-items: center;
  border-radius: $border-radius-small;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin: 0 0.5rem;
  padding: 15px 20px;

  &:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
