.dropzone {
  label.btn {
    margin-bottom: 0;
  }
  .item-row-wrapper {
    position: relative;
    background-color: #cce0ed;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.1) 0px 4px 16px;

    .floating-delete-button {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: red;
      right: -3.1rem;
      top: -0.8rem;
      width: 3rem;
      height: 3rem;
      i {
        font-size: $font-size-xl;
      }
    }
  }

  .dzu-dropzone {
    overflow: auto;
    min-height: 100vh;
    height: 100%;
    border: 1px solid #d9d9d9;
    background-color: $white;
  }

  .dzu-inputLabel {
    .dropzone-button-wrapper {
      .btn {
        margin-top: 0.5rem;
      }
      p {
        color: $body-color;
        font-size: $font-size-sm;
        margin-bottom: 0;
        // line-height: ;
      }
    }
  }

  .dropzone-categories-text {
    font-size: 0.8rem;
  }
}

.form-field-contain .dzu-dropzone {
  background-color: #fff;
  margin-top: 0.5rem;
  overflow: auto;
}
