.trunk {
  .branches-wrapper {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    margin-top: 1rem;
  }
}

.drawer-row {
  border-radius: $border-radius-small;
  display: flex;
  font-size: 12px;
  margin: 0 1rem;
  padding: 1rem 0;

  &.drawer-header {
    font-size: 10px;
    text-transform: uppercase;

    > div:first-of-type:before {
      content: ' ';
      width: 4.5rem;
    }
  }

  > div {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;

    &:first-of-type {
      justify-content: flex-start;
    }

    &.collection-name {
      flex: 8;
    }

    &.content-name {
      flex: 10;
    }

    &.collection-added-by,
    &.collection-add {
      flex: 2;
    }

    &.content-added-by,
    &.content-last-updated,
    &.content-add,
    &.content-category {
      flex: 3;
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i {
      font-size: 1.375rem;
      min-width: 4.5rem;
      text-align: center;
    }
  }
}

.tree-drawer-row {
  .collection-ellipsis {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }
}

.tree-uploader-wrapper {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;

  .tree-uploader-bg {
    background: #fff;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .dropzone-wrapper {
    background-color: #fff;
    border-radius: 1.5rem 1.5rem 0 0;
    filter: drop-shadow(0 0 0.45rem #666);
    height: 85%;
    overflow: hidden;
    position: relative;
    width: 85%;
    z-index: 2;
  }

  .tree-collection-modal-wrapper {
    background-color: #fff;
    border-radius: 1.5rem;
    filter: drop-shadow(0 0 0.45rem #666);
    height: 250px;
    width: 90%;
  }
}

.tree-modal-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;

  &.bottom-aligned {
    align-items: flex-end;

    .tree-modal-container {
      height: 85%;
    }
  }

  .tree-uploader-bg {
    background: #fff;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .tree-modal-container {
    background-color: #fff;
    border-radius: $border-radius-small;
    filter: drop-shadow(0 0 0.45rem #666);
    width: 75%;
    z-index: 2;

    .collection-name-input {
      border: none;
      font-size: 1rem;
      min-width: 50%;
      margin-right: 1rem;
      padding: 1rem;
    }

    .tree-dropdown {
      border-top: 1px solid #000;
      margin-top: 2rem;
      padding: 1rem;
    }

    .preview-header {
      align-items: center;
      background-color: #fff;
      border-radius: $border-radius-small;
      display: flex;
      filter: drop-shadow(0 0 0.45rem rgb(198, 198, 198));
      padding: 1rem;

      .preview-header-left {
        align-items: center;
        display: flex;
        flex: 1;

        i {
          background-color: $brand-dark-gray;
          border-radius: $border-radius-small;
          color: #fff;
          font-size: 1.5rem;
          margin-right: 1rem;
          padding: 1rem;
        }

        div {
          display: flex;
          flex-direction: column;

          .preview-filetype {
            font-style: italic;
            text-transform: uppercase;
          }

          .preview-filename {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }
      }

      .preview-header-right {
        align-items: center;
        display: flex;

        div {
          display: flex;
          flex-direction: column;
          font-size: 0.8rem;
          margin: 0 1.5rem;

          span:first-of-type {
            font-weight: bold;
            text-transform: uppercase;
          }

          &.external-link {
            background-color: var(--brand-primary-color);
            border-radius: 2rem;
            color: #fff;
            font-size: 1.5rem;
            padding: 1rem;
          }

          &.close {
            color: #8898aa;
            font-size: 2.5rem;
            font-weight: normal;
            opacity: 1;
          }
        }
      }
    }
  }
}
