$brand-dark-gray: #3c3c3c;
$brand-dark-gray--darker: #1f1f1f;

$border-radius-large: 16px;
$border-radius-small: 8px;
$border-radius-tiny: 4px;
$box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);

$space: 1rem;

$space-9: -($spacer * 10);
$space-8: -($spacer * 8);
$space-7: -($spacer * 6);
$space-6: -($spacer * 4.5);
$space-5: -($spacer * 3);
$space-4: -($spacer * 1.5);
$space-3: -$spacer;
$space-2: -($spacer * 0.5);
$space-1: -($spacer * 0.25);
$space0: 0;
$space1: ($spacer * 0.25);
$space2: ($spacer * 0.5);
$space3: $spacer;
$space4: ($spacer * 1.5);
$space5: ($spacer * 3);
$space6: ($spacer * 4.5);
$space7: ($spacer * 6);
$space8: ($spacer * 8);
$space9: ($spacer * 10);
