.trunk {
  padding: 2rem;
  padding-left: 3rem;

  .banner-contain {
    display: flex;
    margin-top: 1rem;
    position: relative;

    .back-button {
      cursor: pointer;
      left: -30px;
      position: absolute;
      top: -19px;

      i {
        color: #0065a3;
        font-size: 45px;
      }
    }

    .banner-segment {
      border-top: 2px solid #0065a3;
      border-right: 2px solid #0065a3;
      height: 8px;
      margin-right: 16px;
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .segment-title {
        background-color: #f8f9fe;
        font-size: 9px;
        font-weight: 800;
        left: 10px;
        padding: 0 5px;
        position: absolute;
        text-transform: uppercase;
        top: -7px;
      }
    }
  }

  .edit-config-button {
    background-color: var(--brand-primary-color);
    color: #fff;
    flex: 1;

    &:hover {
      transform: unset;
    }
  }

  .content-tree-header {
    display: flex;
    position: relative;

    .root-views-dropdown {
      align-items: center;
      border: 1px solid var(--brand-primary-color);
      border-radius: $border-radius-large;
      color: var(--brand-primary-color);
      cursor: pointer;
      display: flex;
      font-size: 0.8rem;
      justify-content: space-between;
      padding: 0.275rem 0.8rem;
      width: 283px;
    }

    .root-views-dropdown-options {
      background-color: #fff;
      border-radius: 1rem;
      color: var(--brand-primary-color);
      cursor: pointer;
      filter: drop-shadow(0px 0px 5px rgb(209, 209, 209));
      font-size: 0.8rem;
      padding: 0.5rem 0.8rem;
      position: absolute;
      top: calc(100% + 0.5rem);
      width: 283px;
      z-index: 100000;

      .root-views-dropdown-option {
        padding: 0.5rem 0;
        transition: 0.2s;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .card-stack-wrapper {
    flex: 1;

    &.is-root .card-stack-list {
      background-color: #151515;
    }

    .card-stack-title {
      font-size: 0.7rem;
      margin-left: 1rem;
      margin-top: -0.2rem;
      text-transform: uppercase;
    }

    .card-stack-list {
      background-color: #4c4c4c;
      border-color: #333;
      margin-top: 1rem;

      .stack-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-bottom: 0;
        padding: 0.5rem 0 0.5rem 1rem;
        position: relative;
        transition: 0.2s;

        &:hover,
        &.is-active {
          background-color: #7a7a7a;
        }

        &.is-root {
          display: block;
        }

        .component-name {
          display: block;
          font-size: 0.8rem;
          font-style: italic;
        }

        .stack-item-contents {
          flex: 1;
          overflow: hidden;
          padding-right: 1rem;
          text-overflow: ellipsis;
          white-space: nowrap;

          i {
            text-align: center;
            width: 2rem;
          }

          .item-name {
            font-weight: bold;
          }
        }

        .stack-item-menu-icon {
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          z-index: 2;
        }

        .stack-item-menu-backsplash {
          height: 100%;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 1;
        }

        .stack-item-menu-wrapper {
          border-radius: $border-radius-small;
          box-shadow: $box-shadow;
          position: absolute;
          right: 0;
          top: 100%;

          .menu-item {
            background-color: #303030;
            min-width: 200px;
            padding: 0.5rem 1rem;
            position: relative;
            z-index: 3;

            &:first-of-type {
              border-top-left-radius: $border-radius-small;
              border-top-right-radius: $border-radius-small;
            }

            &:last-of-type {
              border-bottom-left-radius: $border-radius-small;
              border-bottom-right-radius: $border-radius-small;
            }
          }
        }
      }
    }

    .empty-stack-text {
      color: #4d4d4d;
      font-size: 21px;
      font-weight: 900;
      text-align: center;

      &:first-of-type {
        padding-top: 2rem;
      }

      &:last-of-type {
        padding-bottom: 2rem;
      }
    }

    .add-button-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;

      .add-button-icon {
        color: var(--brand-primary-color);
        cursor: pointer;
        font-size: 2.5rem;
      }

      .is-adding-container {
        background-color: var(--brand-primary-color);
        display: flex;
        margin-top: 1rem;
        width: 100%;

        .add-content-row {
          align-items: center;
          display: flex;
          justify-content: space-between;

          & + .add-content-row {
            margin-top: 0.5rem;
          }

          p {
            font-size: 0.8rem;
            margin: 0;
          }
        }
      }
    }
  }
}
