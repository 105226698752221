.items-page .grid-table-card-header .action-menu {
  background-color: #fff;
}

.grid-table-card-header {
  .action-menu {
    background-color: var(--brand-primary-color);
    transform: translate3d(-200px, 46px, 0px) !important;

    .dropdown-item {
      color: $white;
    }

    .dropdown-item:hover {
      background-color: rgba($color: $black, $alpha: 0.2);
    }
  }
}

.drawer-modal-wrapper .card-body {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

.item-row-wrapper {
  border-radius: 0.5rem;
  border: 1px solid transparent;

  h5 {
    position: relative;
    display: inline;
  }

  &:focus {
    border: 1px solid $gray-400;
    z-index: 1000;
  }

  .addButton,
  .removeButton {
    width: 2rem;
    height: 2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    border: none;
    margin: 0;
    text-decoration: none;
    background: transparent;
    border-radius: 1rem;

    i {
      vertical-align: middle;
    }
  }

  .addButton {
    background: var(--brand-primary-color);
    color: var(--theme-dark-text-color);
  }
}

.item-row-wrapper:nth-child(odd) {
  background-color: #f9f9f9;
}

.item-row-editable-text {
  position: relative;
  display: inline;
  width: 100%;
}

.darker-row {
  border-radius: 0.3rem;
  background-color: rgba(0, 0, 0, 0.1);

  h5 {
    display: block;
    color: #7c7d80;
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.row-badge {
  color: $white;
  display: inline-block;
  border-radius: 0.2rem;
  padding: 0.1rem 0.1rem;
  font-size: $font-size-tn;
  width: 5rem;
  text-transform: lowercase;

  &.active {
    background-color: var(--brand-primary-color);
  }
  &.deactivated {
    background-color: var(--brand-primary-color);
    opacity: 0.5;
  }
  &.deleted {
    background-color: #a4a4a4;
    opacity: 0.5;
  }
  &.error {
    background-color: #b01422;
  }
  &.incomplete {
    background-color: #a4a4a4;
  }
  &.pending {
    background-color: #a4a4a4;
  }
  &.invited {
    background-color: #a4a4a4;
  }
}

.validation-notice p {
  font-size: $font-size-sm;
}

.last-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.center-items {
  .multi-edit-wrapper {
    justify-content: center;
  }
}

.text-centered {
  text-align: center;
}

.item-row-horizontal {
  // overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-column-subtitle {
  margin-bottom: 0;
}

.item-row-type {
  width: 100%;
  display: inline-block;

  h5 {
    display: inline-block;
    width: 7rem;
  }

  .btn:hover {
    transform: none;
  }

  .btn-default:hover {
    color: var(--theme-dark-text-color);
    background-color: var(--brand-primary-color);
    border-color: var(--brand-primary-color);
  }

  .close-btn {
    margin: 0;
    margin-left: 0.2rem !important;
  }
}

.edit-button {
  background-color: $brand-dark-gray;
  border-color: $brand-dark-gray;
  color: $white;
}

.edit-button:hover {
  background-color: $brand-dark-gray--darker;
  border-color: $brand-dark-gray--darker;
}

.scrollable-dropdown-menu {
  padding: 0;
}

.dropdown-container {
  position: relative;
  max-height: 20rem;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0.5rem;
}

.help-dropdown-item {
  width: 30rem;
  padding: $space3;

  h4 {
    margin-bottom: $space3;
  }

  h5 {
    span {
      padding-right: $space2;
    }
  }

  p {
    margin-left: 1rem;
    word-break: normal;
    white-space: normal;
  }

  i {
    margin-right: 0.2rem;
    padding: 0.5rem;
    background-color: var(--brand-primary-color);
    color: $white;
    border-radius: 50%;
  }
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $gray-500;
}

.header-column {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.header-column-last {
  align-items: flex-end;
}

.header-column-centered {
  align-items: center;
  text-align: 'center';
}

.card-stats {
  h5 {
    display: block;
    color: var(--brand-primary-color);
  }

  .align-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.item-row-wrapper.is-new {
  background-color: var(--brand-primary-color);

  &.invert-child-colors-on-new,
  & .invert-child-colors-on-new {
    h5,
    .form-control-label,
    .title-wrapper span,
    .multi-edit-button,
    .validation-notice,
    .labeled-checkbox-label,
    .fa-pencil-alt,
    .fa-ellipsis-v {
      color: $white;
      border-color: $white;
    }

    :not(.dropdown-menu) {
      .btn {
        background-color: $white;
        color: var(--brand-primary-color);
      }

      .btn.disabled {
        opacity: 0.5;
      }
    }

    .dropdown-menu {
      i + span,
      input + span,
      i {
        color: #7c7d80;
      }
    }
  }
}

// @-o-keyframes animateBackgroundColor {
//   0% {
//     background-color: var(--brand-primary-color);
//   }
//   20% {
//     background-color: var(--brand-primary-color);
//   }
//   100% {
//     background-color: #f9f9f9;
//   }
// }
// @keyframes animateBackgroundColor {
//   0% {
//     background-color: var(--brand-primary-color);
//   }
//   20% {
//     background-color: var(--brand-primary-color);
//   }
//   100% {
//     background-color: #f9f9f9;
//   }
// }

// .background-animated {
//   -o-animation: animateBackgroundColor 2s ease-in-out;
//   animation: animateBackgroundColor 2s ease-in-out;
// }

// @-o-keyframes animateTextColor {
//   0% {
//     color: $white;
//     border-color: $white;
//   }
//   60% {
//     color: inherit;
//     border-color: inherit;
//   }
//   100% {
//     color: inherit;
//     border-color: inherit;
//   }
// }
// @keyframes animateTextColor {
//   0% {
//     color: $white;
//     border-color: $white;
//   }
//   60% {
//     color: inherit;
//     border-color: inherit;
//   }
//   100% {
//     color: inherit;
//     border-color: inherit;
//   }
// }

// .background-animated {
//   h5,
//   .item-row-horizontal *,
//   .collapse *:not(.btn) {
//     -o-animation: animateTextColor 2s ease-in-out;
//     animation: animateTextColor 2s ease-in-out;
//   }
// }

// @-o-keyframes animateBtn {
//   0% {
//     color: var(--brand-primary-color);
//     background-color: $white;
//   }
//   60% {
//     color: $white;
//   }
//   100% {
//     color: $white;
//   }
// }
// @keyframes animateBtn {
//   0% {
//     color: var(--brand-primary-color);
//     background-color: $white;
//   }
//   60% {
//     color: $white;
//   }
//   100% {
//     color: $white;
//   }
// }

// .background-animated {
//   .btn {
//     -o-animation: animateBtn 2s ease-in-out;
//     animation: animateBtn 2s ease-in-out;
//   }
// }

// //.background-animated
// // .background-animated *:not(.btn),
// // .background-animated *:not(.dropdown-item) {
// //   -o-animation: animateTextColor 3s ease-in-out;
// //   animation: animateTextColor 3s ease-in-out;
// // }
