.z-index-10000 {
  position: fixed;
  z-index: 10000;
}
.drawer-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.6);

  backdrop-filter: blur(3px) opacity(0);
  transition: backdrop-filter 0.2s ease-out;
}

.drawer-modal-wrapper.open {
  backdrop-filter: blur(3px) opacity(1);
  height: 100%;
}

.drawer-modal {
  border: 1px solid $gray-300;
  border-radius: $border-radius-large;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  height: 90%;
  margin-bottom: -20px;
  padding-bottom: 20px;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
  width: 80%;

  .close-button {
    font-size: 2rem;
    position: absolute;
    top: -2rem;
    right: 0.2rem;
  }

  .close-button:hover {
    color: $gray-800;
  }

  .card-header {
    background-color: transparent;
  }
}

.drawer-modal-wrapper.open {
  .drawer-modal {
    transform: translateX(0);
  }
}

// @keyframes in {
//   from {
//     -webkit-transform: rotate(0deg);
//   }
//   to {
//     -webkit-transform: rotate(360deg);
//   }
// }

// @keyframes out {
//   0% {
//     -webkit-transform: rotate(360deg);
//   }
//   100% {
//     -webkit-transform: rotate(0deg);
//   }
// }

// @-webkit-keyframes in {
//   from {
//     -webkit-transform: rotate(0deg);
//   }
//   to {
//     -webkit-transform: rotate(360deg);
//   }
// }

// @-webkit-keyframes out {
//   0% {
//     -webkit-transform: rotate(360deg);
//   }
//   100% {
//     -webkit-transform: rotate(0deg);
//   }
// }
